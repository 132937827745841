const autoNgTemplateLoaderTemplate1 = require('./wizard-endpoint-list.html');

import angular from 'angular';
import './wizard-endpoint-list.css';

angular.module('portainer.app').component('wizardEndpointList', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    endpointList: '<',
  },
});
