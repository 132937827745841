const autoNgTemplateLoaderTemplate1 = require('./volumes.html');

angular.module('portainer.kubernetes').component('kubernetesVolumesView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesVolumesController',
  controllerAs: 'ctrl',
  bindings: {
    $transition$: '<',
  },
});
