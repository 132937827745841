const autoNgTemplateLoaderTemplate1 = require('./wizard-docker.html');

import angular from 'angular';
import controller from './wizard-docker.controller.js';

angular.module('portainer.app').component('wizardDocker', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    onUpdate: '<',
    onAnalytics: '<',
  },
});
