const autoNgTemplateLoaderTemplate1 = require('./wizard-aci.html');

import angular from 'angular';
import controller from './wizard-aci.controller.js';

angular.module('portainer.app').component('wizardAci', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    onUpdate: '<',
    onAnalytics: '<',
  },
});
