const autoNgTemplateLoaderTemplate1 = require('./wizard-kubernetes.html');

import angular from 'angular';
import controller from './wizard-kubernetes.controller.js';

angular.module('portainer.app').component('wizardKubernetes', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    onUpdate: '<',
    onAnalytics: '<',
  },
});
