const autoNgTemplateLoaderTemplate1 = require('./por-switch.html');

import angular from 'angular';
import controller from './por-switch.controller';

import './por-switch.css';

const porSwitch = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    ngModel: '=',
    id: '@',
    className: '@',
    name: '@',
    ngDataCy: '@',
    disabled: '<',
    onChange: '<',
    feature: '<', // feature id
  },
};

angular.module('portainer.app').component('porSwitch', porSwitch);
