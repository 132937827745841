const autoNgTemplateLoaderTemplate1 = require('./views/configs/configs.html');
const autoNgTemplateLoaderTemplate2 = require('./views/configs/edit/config.html');
const autoNgTemplateLoaderTemplate3 = require('./views/configs/create/createconfig.html');
const autoNgTemplateLoaderTemplate4 = require('./views/containers/containers.html');
const autoNgTemplateLoaderTemplate5 = require('./views/containers/edit/container.html');
const autoNgTemplateLoaderTemplate6 = require('./views/containers/console/attach.html');
const autoNgTemplateLoaderTemplate7 = require('./views/containers/console/exec.html');
const autoNgTemplateLoaderTemplate8 = require('./views/containers/create/createcontainer.html');
const autoNgTemplateLoaderTemplate9 = require('./views/containers/inspect/containerinspect.html');
const autoNgTemplateLoaderTemplate10 = require('./views/containers/logs/containerlogs.html');
const autoNgTemplateLoaderTemplate11 = require('./views/containers/stats/containerstats.html');
const autoNgTemplateLoaderTemplate12 = require('./views/dashboard/dashboard.html');
const autoNgTemplateLoaderTemplate13 = require('./views/events/events.html');
const autoNgTemplateLoaderTemplate14 = require('./views/images/images.html');
const autoNgTemplateLoaderTemplate15 = require('./views/images/edit/image.html');
const autoNgTemplateLoaderTemplate16 = require('./views/images/build/buildimage.html');
const autoNgTemplateLoaderTemplate17 = require('./views/images/import/importimage.html');
const autoNgTemplateLoaderTemplate18 = require('./views/networks/networks.html');
const autoNgTemplateLoaderTemplate19 = require('./views/networks/edit/network.html');
const autoNgTemplateLoaderTemplate20 = require('./views/networks/create/createnetwork.html');
const autoNgTemplateLoaderTemplate21 = require('./views/secrets/secrets.html');
const autoNgTemplateLoaderTemplate22 = require('./views/secrets/edit/secret.html');
const autoNgTemplateLoaderTemplate23 = require('./views/secrets/create/createsecret.html');
const autoNgTemplateLoaderTemplate24 = require('./views/services/services.html');
const autoNgTemplateLoaderTemplate25 = require('./views/services/edit/service.html');
const autoNgTemplateLoaderTemplate26 = require('./views/services/create/createservice.html');
const autoNgTemplateLoaderTemplate27 = require('./views/services/logs/servicelogs.html');
const autoNgTemplateLoaderTemplate28 = require('Portainer/views/stacks/stacks.html');
const autoNgTemplateLoaderTemplate29 = require('Portainer/views/stacks/edit/stack.html');
const autoNgTemplateLoaderTemplate30 = require('Portainer/views/stacks/create/createstack.html');
const autoNgTemplateLoaderTemplate31 = require('./views/swarm/swarm.html');
const autoNgTemplateLoaderTemplate32 = require('./views/swarm/visualizer/swarmvisualizer.html');
const autoNgTemplateLoaderTemplate33 = require('./views/tasks/edit/task.html');
const autoNgTemplateLoaderTemplate34 = require('./views/tasks/logs/tasklogs.html');
const autoNgTemplateLoaderTemplate35 = require('Portainer/views/templates/templates.html');
const autoNgTemplateLoaderTemplate36 = require('./views/volumes/volumes.html');
const autoNgTemplateLoaderTemplate37 = require('./views/volumes/edit/volume.html');
const autoNgTemplateLoaderTemplate38 = require('./views/volumes/browse/browsevolume.html');
const autoNgTemplateLoaderTemplate39 = require('./views/volumes/create/createvolume.html');

angular.module('portainer.docker', ['portainer.app']).config([
  '$stateRegistryProvider',
  function ($stateRegistryProvider) {
    'use strict';

    var docker = {
      name: 'docker',
      parent: 'endpoint',
      url: '/docker',
      abstract: true,
      onEnter: /* @ngInject */ function onEnter(endpoint, $async, $state, EndpointService, EndpointProvider, LegacyExtensionManager, Notifications, StateManager, SystemService) {
        return $async(async () => {
          if (![1, 2, 4].includes(endpoint.Type)) {
            $state.go('portainer.home');
            return;
          }
          try {
            const status = await checkEndpointStatus(endpoint);

            if (endpoint.Type !== 4) {
              await updateEndpointStatus(endpoint, status);
            }
            endpoint.Status = status;

            if (status === 2) {
              if (!endpoint.Snapshots[0]) {
                throw new Error('Environment is unreachable and there is no snapshot available for offline browsing.');
              }
              if (endpoint.Snapshots[0].Swarm) {
                throw new Error('Environment is unreachable. Connect to another swarm manager.');
              }
            }

            EndpointProvider.setEndpointID(endpoint.Id);
            EndpointProvider.setEndpointPublicURL(endpoint.PublicURL);
            EndpointProvider.setOfflineModeFromStatus(endpoint.Status);

            const extensions = await LegacyExtensionManager.initEndpointExtensions(endpoint);
            await StateManager.updateEndpointState(endpoint, extensions);
          } catch (e) {
            Notifications.error('Failed loading environment', e);
            $state.go('portainer.home', {}, { reload: true });
          }

          async function checkEndpointStatus(endpoint) {
            try {
              await SystemService.ping(endpoint.Id);
              return 1;
            } catch (e) {
              return 2;
            }
          }

          async function updateEndpointStatus(endpoint, status) {
            if (endpoint.Status === status) {
              return;
            }
            await EndpointService.updateEndpoint(endpoint.Id, { Status: status });
          }
        });
      },
    };

    var configs = {
      name: 'docker.configs',
      url: '/configs',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate1,
          controller: 'ConfigsController',
          controllerAs: 'ctrl',
        },
      },
    };

    var config = {
      name: 'docker.configs.config',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate2,
          controller: 'ConfigController',
        },
      },
    };

    var configCreation = {
      name: 'docker.configs.new',
      url: '/new?id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate3,
          controller: 'CreateConfigController',
          controllerAs: 'ctrl',
        },
      },
    };

    var containers = {
      name: 'docker.containers',
      url: '/containers',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate4,
          controller: 'ContainersController',
        },
      },
    };

    var container = {
      name: 'docker.containers.container',
      url: '/:id?nodeName',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate5,
          controller: 'ContainerController',
        },
      },
    };

    var containerAttachConsole = {
      name: 'docker.containers.container.attach',
      url: '/attach',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate6,
          controller: 'ContainerConsoleController',
        },
      },
    };

    var containerExecConsole = {
      name: 'docker.containers.container.exec',
      url: '/exec',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate7,
          controller: 'ContainerConsoleController',
        },
      },
    };

    var containerCreation = {
      name: 'docker.containers.new',
      url: '/new?nodeName&from',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate8,
          controller: 'CreateContainerController',
        },
      },
    };

    var containerInspect = {
      name: 'docker.containers.container.inspect',
      url: '/inspect',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate9,
          controller: 'ContainerInspectController',
        },
      },
    };

    var containerLogs = {
      name: 'docker.containers.container.logs',
      url: '/logs',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate10,
          controller: 'ContainerLogsController',
        },
      },
    };

    var containerStats = {
      name: 'docker.containers.container.stats',
      url: '/stats',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate11,
          controller: 'ContainerStatsController',
        },
      },
    };

    const customTemplates = {
      name: 'docker.templates.custom',
      url: '/custom',

      views: {
        'content@': {
          component: 'customTemplatesView',
        },
      },
    };

    const customTemplatesNew = {
      name: 'docker.templates.custom.new',
      url: '/new?fileContent&type',

      views: {
        'content@': {
          component: 'createCustomTemplateView',
        },
      },
      params: {
        fileContent: '',
        type: '',
      },
    };

    const customTemplatesEdit = {
      name: 'docker.templates.custom.edit',
      url: '/:id',

      views: {
        'content@': {
          component: 'editCustomTemplateView',
        },
      },
    };

    var dashboard = {
      name: 'docker.dashboard',
      url: '/dashboard',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate12,
          controller: 'DashboardController',
        },
      },
    };

    var host = {
      name: 'docker.host',
      url: '/host',
      views: {
        'content@': {
          component: 'hostView',
        },
      },
    };

    var hostBrowser = {
      name: 'docker.host.browser',
      url: '/browser',
      views: {
        'content@': {
          component: 'hostBrowserView',
        },
      },
    };

    var events = {
      name: 'docker.events',
      url: '/events',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate13,
          controller: 'EventsController',
        },
      },
    };

    var images = {
      name: 'docker.images',
      url: '/images',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate14,
          controller: 'ImagesController',
        },
      },
    };

    var image = {
      name: 'docker.images.image',
      url: '/:id?nodeName',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate15,
          controller: 'ImageController',
        },
      },
    };

    var imageBuild = {
      name: 'docker.images.build',
      url: '/build',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate16,
          controller: 'BuildImageController',
        },
      },
    };

    var imageImport = {
      name: 'docker.images.import',
      url: '/import',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate17,
          controller: 'ImportImageController',
        },
      },
    };

    var networks = {
      name: 'docker.networks',
      url: '/networks',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate18,
          controller: 'NetworksController',
        },
      },
    };

    var network = {
      name: 'docker.networks.network',
      url: '/:id?nodeName',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate19,
          controller: 'NetworkController',
        },
      },
    };

    var networkCreation = {
      name: 'docker.networks.new',
      url: '/new',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate20,
          controller: 'CreateNetworkController',
        },
      },
    };

    var nodes = {
      name: 'docker.nodes',
      url: '/nodes',
      abstract: true,
    };

    var node = {
      name: 'docker.nodes.node',
      url: '/:id',
      views: {
        'content@': {
          component: 'nodeDetailsView',
        },
      },
    };

    var nodeBrowser = {
      name: 'docker.nodes.node.browse',
      url: '/browse',
      views: {
        'content@': {
          component: 'nodeBrowserView',
        },
      },
    };

    var secrets = {
      name: 'docker.secrets',
      url: '/secrets',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate21,
          controller: 'SecretsController',
        },
      },
    };

    var secret = {
      name: 'docker.secrets.secret',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate22,
          controller: 'SecretController',
        },
      },
    };

    var secretCreation = {
      name: 'docker.secrets.new',
      url: '/new',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate23,
          controller: 'CreateSecretController',
        },
      },
    };

    var services = {
      name: 'docker.services',
      url: '/services',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate24,
          controller: 'ServicesController',
        },
      },
    };

    var service = {
      name: 'docker.services.service',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate25,
          controller: 'ServiceController',
        },
      },
    };

    var serviceCreation = {
      name: 'docker.services.new',
      url: '/new',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate26,
          controller: 'CreateServiceController',
        },
      },
    };

    var serviceLogs = {
      name: 'docker.services.service.logs',
      url: '/logs',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate27,
          controller: 'ServiceLogsController',
        },
      },
    };

    var stacks = {
      name: 'docker.stacks',
      url: '/stacks',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate28,
          controller: 'StacksController',
        },
      },
    };

    var stack = {
      name: 'docker.stacks.stack',
      url: '/:name?id&type&regular&external&orphaned&orphanedRunning',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate29,
          controller: 'StackController',
        },
      },
    };

    var stackCreation = {
      name: 'docker.stacks.newstack',
      url: '/newstack',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate30,
          controller: 'CreateStackController',
        },
      },
    };

    var swarm = {
      name: 'docker.swarm',
      url: '/swarm',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate31,
          controller: 'SwarmController',
        },
      },
    };

    var swarmVisualizer = {
      name: 'docker.swarm.visualizer',
      url: '/visualizer',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate32,
          controller: 'SwarmVisualizerController',
        },
      },
    };

    var tasks = {
      name: 'docker.tasks',
      url: '/tasks',
      abstract: true,
    };

    var task = {
      name: 'docker.tasks.task',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate33,
          controller: 'TaskController',
        },
      },
    };

    var taskLogs = {
      name: 'docker.tasks.task.logs',
      url: '/logs',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate34,
          controller: 'TaskLogsController',
        },
      },
    };

    var templates = {
      name: 'docker.templates',
      url: '/templates',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate35,
          controller: 'TemplatesController',
        },
      },
    };

    var volumes = {
      name: 'docker.volumes',
      url: '/volumes',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate36,
          controller: 'VolumesController',
        },
      },
    };

    var volume = {
      name: 'docker.volumes.volume',
      url: '/:id?nodeName',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate37,
          controller: 'VolumeController',
        },
      },
    };

    var volumeBrowse = {
      name: 'docker.volumes.volume.browse',
      url: '/browse',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate38,
          controller: 'BrowseVolumeController',
        },
      },
    };

    var volumeCreation = {
      name: 'docker.volumes.new',
      url: '/new',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate39,
          controller: 'CreateVolumeController',
        },
      },
    };

    const dockerFeaturesConfiguration = {
      name: 'docker.featuresConfiguration',
      url: '/feat-config',
      views: {
        'content@': {
          component: 'dockerFeaturesConfigurationView',
        },
      },
    };

    const registries = {
      name: 'docker.registries',
      url: '/registries',
      views: {
        'content@': {
          component: 'endpointRegistriesView',
        },
      },
    };

    const registryAccess = {
      name: 'docker.registries.access',
      url: '/:id/access',
      views: {
        'content@': {
          component: 'dockerRegistryAccessView',
        },
      },
    };

    $stateRegistryProvider.register(configs);
    $stateRegistryProvider.register(config);
    $stateRegistryProvider.register(configCreation);
    $stateRegistryProvider.register(containers);
    $stateRegistryProvider.register(container);
    $stateRegistryProvider.register(containerExecConsole);
    $stateRegistryProvider.register(containerAttachConsole);
    $stateRegistryProvider.register(containerCreation);
    $stateRegistryProvider.register(containerInspect);
    $stateRegistryProvider.register(containerLogs);
    $stateRegistryProvider.register(containerStats);
    $stateRegistryProvider.register(customTemplates);
    $stateRegistryProvider.register(customTemplatesNew);
    $stateRegistryProvider.register(customTemplatesEdit);
    $stateRegistryProvider.register(docker);
    $stateRegistryProvider.register(dashboard);
    $stateRegistryProvider.register(host);
    $stateRegistryProvider.register(hostBrowser);
    $stateRegistryProvider.register(events);
    $stateRegistryProvider.register(images);
    $stateRegistryProvider.register(image);
    $stateRegistryProvider.register(imageBuild);
    $stateRegistryProvider.register(imageImport);
    $stateRegistryProvider.register(networks);
    $stateRegistryProvider.register(network);
    $stateRegistryProvider.register(networkCreation);
    $stateRegistryProvider.register(nodes);
    $stateRegistryProvider.register(node);
    $stateRegistryProvider.register(nodeBrowser);
    $stateRegistryProvider.register(secrets);
    $stateRegistryProvider.register(secret);
    $stateRegistryProvider.register(secretCreation);
    $stateRegistryProvider.register(services);
    $stateRegistryProvider.register(service);
    $stateRegistryProvider.register(serviceCreation);
    $stateRegistryProvider.register(serviceLogs);
    $stateRegistryProvider.register(stacks);
    $stateRegistryProvider.register(stack);
    $stateRegistryProvider.register(stackCreation);
    $stateRegistryProvider.register(swarm);
    $stateRegistryProvider.register(swarmVisualizer);
    $stateRegistryProvider.register(tasks);
    $stateRegistryProvider.register(task);
    $stateRegistryProvider.register(taskLogs);
    $stateRegistryProvider.register(templates);
    $stateRegistryProvider.register(volumes);
    $stateRegistryProvider.register(volume);
    $stateRegistryProvider.register(volumeBrowse);
    $stateRegistryProvider.register(volumeCreation);
    $stateRegistryProvider.register(dockerFeaturesConfiguration);
    $stateRegistryProvider.register(registries);
    $stateRegistryProvider.register(registryAccess);
  },
]);
