const autoNgTemplateLoaderTemplate1 = require('./groupForm.html');

import angular from 'angular';

import { EdgeGroupFormController } from './groupFormController';

angular.module('portainer.edge').component('edgeGroupForm', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EdgeGroupFormController,
  bindings: {
    model: '<',
    groups: '<',
    tags: '<',
    formActionLabel: '@',
    formAction: '<',
    actionInProgress: '<',
    loaded: '<',
    pageType: '@',
  },
});
