const autoNgTemplateLoaderTemplate1 = require('./registry-form-quay.html');

angular.module('portainer.app').component('registryFormQuay', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    model: '=',
    formAction: '<',
    formActionLabel: '@',
    actionInProgress: '<',
  },
});
