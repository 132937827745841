const autoNgTemplateLoaderTemplate1 = require('./storidgeNodesDatatable.html');

angular.module('portainer.integrations.storidge').component('storidgeNodesDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'StoridgeNodesDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
  },
});
