const autoNgTemplateLoaderTemplate1 = require('./header-title.html');

import controller from './header-title.controller';

export const rdHeaderTitle = {
  requires: '^rdHeader',
  bindings: {
    titleText: '@',
  },
  transclude: true,
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
};
