const autoNgTemplateLoaderTemplate1 = require('./storidgeDrivesDatatable.html');

angular.module('portainer.integrations.storidge').component('storidgeDrivesDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'StoridgeDrivesDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    removeAction: '<',
    addAction: '<',
    rescanAction: '<',
    actionInProgress: '<',
    additionInProgress: '<',
  },
});
