const autoNgTemplateLoaderTemplate1 = require('./motdPanel.html');

angular.module('portainer.app').component('motdPanel', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    motd: '<',
    dismissAction: '&?',
  },
  transclude: true,
});
