const autoNgTemplateLoaderTemplate1 = require('./console.html');

angular.module('portainer.kubernetes').component('kubernetesApplicationConsoleView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesApplicationConsoleController',
  controllerAs: 'ctrl',
  bindings: {
    $transition$: '<',
  },
});
