const autoNgTemplateLoaderTemplate1 = require('./nodeApplicationsDatatable.html');

angular.module('portainer.kubernetes').component('kubernetesNodeApplicationsDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesNodeApplicationsDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    refreshCallback: '<',
  },
});
