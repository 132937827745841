const autoNgTemplateLoaderTemplate1 = require('./tagSelector.html');

angular.module('portainer.app').component('tagSelector', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'TagSelectorController',
  bindings: {
    tags: '<',
    model: '=',
    onCreate: '<',
    allowCreate: '<',
  },
});
