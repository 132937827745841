const autoNgTemplateLoaderTemplate1 = require('./environment-variables-panel.html');

import angular from 'angular';

import './environment-variables-panel.css';

import controller from './environment-variables-panel.controller.js';

angular.module('portainer.app').component('environmentVariablesPanel', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    ngModel: '<',
    explanation: '@',
    onChange: '<',
  },
});
