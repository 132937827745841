const autoNgTemplateLoaderTemplate1 = require('./views/profiles/profiles.html');
const autoNgTemplateLoaderTemplate2 = require('./views/profiles/edit/profile.html');
const autoNgTemplateLoaderTemplate3 = require('./views/drives/drives.html');
const autoNgTemplateLoaderTemplate4 = require('./views/drives/inspect/drive.html');
const autoNgTemplateLoaderTemplate5 = require('./views/snapshots/inspect/snapshot.html');
const autoNgTemplateLoaderTemplate6 = require('./views/profiles/create/createprofile.html');
const autoNgTemplateLoaderTemplate7 = require('./views/cluster/cluster.html');
const autoNgTemplateLoaderTemplate8 = require('./views/nodes/inspect/node.html');
const autoNgTemplateLoaderTemplate9 = require('./views/monitor/monitor.html');

// TODO: legacy extension management

angular.module('portainer.integrations.storidge', []).config([
  '$stateRegistryProvider',
  function ($stateRegistryProvider) {
    'use strict';

    var storidge = {
      name: 'storidge',
      parent: 'root',
      abstract: true,
      url: '/storidge',
    };

    var profiles = {
      name: 'storidge.profiles',
      url: '/profiles',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate1,
          controller: 'StoridgeProfilesController',
        },
      },
    };

    var profile = {
      name: 'storidge.profiles.profile',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate2,
          controller: 'StoridgeProfileController',
        },
      },
    };

    var drives = {
      name: 'storidge.drives',
      url: '/drives',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate3,
          controller: 'StoridgeDrivesController',
        },
      },
    };

    var drive = {
      name: 'storidge.drives.drive',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate4,
          controller: 'StoridgeDriveController',
        },
      },
    };

    var snapshot = {
      name: 'docker.volumes.volume.snapshot',
      url: '/:snapshotId',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate5,
          controller: 'StoridgeSnapshotController',
        },
      },
    };

    var profileCreation = {
      name: 'storidge.profiles.new',
      url: '/new',
      params: {
        profileName: '',
      },
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate6,
          controller: 'StoridgeCreateProfileController',
        },
      },
    };

    var cluster = {
      name: 'storidge.cluster',
      url: '/cluster',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate7,
          controller: 'StoridgeClusterController',
        },
      },
    };

    var node = {
      name: 'storidge.cluster.node',
      url: '/:name',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate8,
          controller: 'StoridgeNodeController',
        },
      },
    };

    var monitor = {
      name: 'storidge.monitor',
      url: '/events',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate9,
          controller: 'StoridgeMonitorController',
        },
      },
    };

    $stateRegistryProvider.register(storidge);
    $stateRegistryProvider.register(drives);
    $stateRegistryProvider.register(drive);
    $stateRegistryProvider.register(snapshot);
    $stateRegistryProvider.register(profiles);
    $stateRegistryProvider.register(profile);
    $stateRegistryProvider.register(profileCreation);
    $stateRegistryProvider.register(cluster);
    $stateRegistryProvider.register(node);
    $stateRegistryProvider.register(monitor);
  },
]);
