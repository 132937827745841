const autoNgTemplateLoaderTemplate1 = require('./viewHeader.html');

angular.module('portainer.kubernetes').component('kubernetesViewHeader', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  transclude: true,
  bindings: {
    viewReady: '<',
    title: '@',
    state: '@',
  },
});
