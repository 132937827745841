const autoNgTemplateLoaderTemplate1 = require('./environment-variables-simple-mode.html');

import angular from 'angular';

import './environment-variables-simple-mode.css';

import controller from './environment-variables-simple-mode.controller';

angular.module('portainer.app').component('environmentVariablesSimpleMode', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    ngModel: '<',
    onSwitchModeClick: '<',
    onChange: '<',
  },
});
