const autoNgTemplateLoaderTemplate1 = require('./containersDatatable.html');

angular.module('portainer.docker').component('containersDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'ContainersDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    showHostColumn: '<',
    showAddAction: '<',
    offlineMode: '<',
    refreshCallback: '<',
    notAutoFocus: '<',
  },
});
