const autoNgTemplateLoaderTemplate1 = require('./access-viewer-datatable.html');

export const accessViewerDatatable = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'GenericDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    tableKey: '@',
    orderBy: '@',
    dataset: '<',
  },
};
