const autoNgTemplateLoaderTemplate1 = require('./kubectl-shell.html');

import angular from 'angular';
import controller from './kubectl-shell.controller';
import './kubectl-shell.css';

angular.module('portainer.kubernetes').component('kubectlShell', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
});
