const autoNgTemplateLoaderTemplate1 = require('./registry-form-custom.html');

angular.module('portainer.app').component('registryFormCustom', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    model: '=',
    formAction: '<',
    formActionLabel: '@',
    actionInProgress: '<',
  },
});
