const autoNgTemplateLoaderTemplate1 = require('./wizard-link.html');

import angular from 'angular';
import './wizard-link.css';

angular.module('portainer.app').component('wizardLink', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    linkTitle: '@',
    description: '@',
    icon: '<',
  },
});
