export const EDITIONS = {
  CE: 0,
  BE: 1,
};

export const STATES = {
  HIDDEN: 0,
  VISIBLE: 1,
  LIMITED_BE: 2,
};
