const autoNgTemplateLoaderTemplate1 = require('./endpointSelector.html');

angular.module('portainer.app').component('endpointSelector', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'EndpointSelectorController',
  bindings: {
    model: '=',
    endpoints: '<',
    groups: '<',
  },
});
