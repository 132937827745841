const autoNgTemplateLoaderTemplate1 = require('./ldap-settings-custom.html');

import controller from './ldap-settings-custom.controller';

export const ldapSettingsCustom = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    settings: '=',
    tlscaCert: '=',
    state: '=',
    selectedAdminGroups: '=',
    onTlscaCertChange: '<',
    connectivityCheck: '<',
    onSearchUsersClick: '<',
    onSearchGroupsClick: '<',
    onSearchAdminGroupsClick: '<',
  },
};
