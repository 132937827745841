const autoNgTemplateLoaderTemplate1 = require('./containersDatatableActions.html');

angular.module('portainer.docker').component('containersDatatableActions', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'ContainersDatatableActionsController',
  bindings: {
    selectedItems: '=',
    selectedItemCount: '=',
    noStoppedItemsSelected: '=',
    noRunningItemsSelected: '=',
    noPausedItemsSelected: '=',
    showAddAction: '<',
  },
});
