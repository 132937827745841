const autoNgTemplateLoaderTemplate1 = require('./wizard-stepper.html');

import angular from 'angular';
import './wizard-stepper.css';

angular.module('portainer.app').component('wizardStepper', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    endpointSelections: '<',
  },
});
