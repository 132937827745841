const autoNgTemplateLoaderTemplate1 = require('./registry-form-dockerhub.html');

angular.module('portainer.app').component('registryFormDockerhub', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    model: '=',
    formAction: '<',
    formActionLabel: '@',
    actionInProgress: '<',
  },
});
