const autoNgTemplateLoaderTemplate1 = require('./sidebar-menu-item.html');

import './sidebar-menu-item.css';

export const sidebarMenuItem = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    path: '@',
    pathParams: '<',
    iconClass: '@',
    className: '@',
  },
  transclude: true,
};
