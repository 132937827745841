const autoNgTemplateLoaderTemplate1 = require('./azure-sidebar.html');

import angular from 'angular';

angular.module('portainer.azure').component('azureSidebar', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    endpointId: '<',
  },
});
