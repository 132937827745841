import 'ui-select/dist/select.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import 'toastr/build/toastr.css';
import 'xterm/dist/xterm.css';
import 'angularjs-slider/dist/rzslider.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/lint/lint.css';
import 'angular-json-tree/dist/angular-json-tree.css';
import 'angular-loading-bar/build/loading-bar.css';
import 'angular-moment-picker/dist/angular-moment-picker.min.css';
import 'angular-multiselect/isteven-multi-select.css';
import 'spinkit/spinkit.min.css';

import angular from 'angular';
import 'moment';
import '@uirouter/angularjs';
import 'ui-select';
import 'angular-sanitize';
import 'ng-file-upload';
import 'angular-messages';
import 'angular-resource';
import 'angular-utils-pagination';
import 'angular-local-storage';
import 'angular-jwt';
import 'angular-json-tree';
import 'angular-loading-bar';
import 'angular-clipboard';
import 'angular-file-saver';
import 'angularjs-scroll-glue';
import 'angularjs-slider';
import 'bootstrap/dist/js/bootstrap.js';
import 'js-yaml/dist/js-yaml.js';
import 'angular-ui-bootstrap';
import 'angular-moment-picker';
import 'angular-multiselect/isteven-multi-select.js';
import 'angulartics/dist/angulartics.min.js';

window.angular = angular;
