const autoNgTemplateLoaderTemplate1 = require('./containerQuickActions.html');

angular.module('portainer.docker').component('containerQuickActions', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    containerId: '<',
    nodeName: '<',
    status: '<',
    state: '<',
    taskId: '<',
  },
});
