const autoNgTemplateLoaderTemplate1 = require('./informationPanel.html');

angular.module('portainer.app').component('informationPanel', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    titleText: '@',
    dismissAction: '&?',
  },
  transclude: true,
});
