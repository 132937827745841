const autoNgTemplateLoaderTemplate1 = require('./header-content.html');

import controller from './header-content.controller';

export const rdHeaderContent = {
  requires: '^rdHeader',
  transclude: true,
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
};
